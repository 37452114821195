import React from 'react';
import logo from "./assets/img/load_b.png";
import './assets/style.css'; // Import the CSS file

export default function Underlay({project}) {
  return (
    <>
      <div className="top-left">
        <img
        className=' fade-in'
          src={logo}
          alt=""
          style={{ zIndex: 2, width: "250px" }}
        />
      </div>
      {project? <div className="bottom-right">{project}</div> : ''}
      <div className="bottom-left">
        Alyssia Rose
        <br />
        <a className="no-link"  href='tel:0645809464'>06 45 80 94 64</a>
        <br />
        <a className="no-link" href='mailto:alyssiarose@bleufuchsia.com'>AlyssiaRose@BleuFuchsia.com</a>
      </div>
      <div className="left-middle">Architecte</div>
    </>
  )
}
