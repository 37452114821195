import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import './assets/style.css';
import App from './App';
import Project from './Project';
import { projects } from './data';
import NotFound from './NotFound';


function RootComponent() {
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    const alreadyVisited = localStorage.getItem('alreadyVisited');
    if (!alreadyVisited) {
      setHasLoaded(false);
      localStorage.setItem('alreadyVisited', 'true');
    } else {
      setHasLoaded(true);
    }
  }, []);

  return (
    <>
      <Routes>
        {projects.map((project) => (
          <Route
            key={project.projectId}
            path={project.projectLink}
            element={<Project project={project} />}
          />
        ))}
        <Route path="/" element={<App />} />
        <Route path="*" element={<NotFound />} />
        <Route path="/project" element={<Project />} />
      </Routes>
    </>
  );
}


const root = ReactDOM.createRoot(document.querySelector('#root'));

root.render(
  <BrowserRouter>
    <RootComponent />
  </BrowserRouter>
);
