import { useRef, useState, useEffect } from 'react'
import { gsap } from 'gsap'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import { projects } from './data'

import '../src/HamburgerMenu.css'
import logo_header from './assets/img/logo_header.png'

const HamburgerMenu = () => {
  const burger = useRef(null)
  const out_menu = useRef(null)
  const [active, setActive] = useState(true)
  const lines = document.querySelectorAll('.line')

  const handleLinkClick = () => {
    setActive(true)
  }

  const handleTransitionToHome = () => {
    const navigate = useNavigate();
  
    startTransition(() => {
      navigate('/');
    });
  };

  useEffect(() => {
    if (!active) {
      gsap.to(out_menu.current, {
        duration: 0.75,
        ease: 'power4.inOut',
        opacity: 1,
        xPercent: -100
      })

      gsap.to(lines[0], {
        rotation: 45,
        x: 0,
        y: 8,
        duration: 0.5,
        ease: 'power4.inOut'
      })
      gsap.to(lines[1], {
        opacity: 0,
        duration: 0.5,
        ease: 'power4.inOut'
      })
      gsap.to(lines[2], {
        rotation: -45,
        x: 0,
        y: -12,
        duration: 0.5,
        ease: 'power4.inOut'
      })
    } else {
      gsap.to(lines[0], {
        rotation: 0,
        x: 0,
        y: 0,
        duration: 0.5,
        ease: 'power4.inOut'
      })
      gsap.to(lines[1], {
        opacity: 1,
        duration: 0.5,
        ease: 'power4.inOut'
      })
      gsap.to(lines[2], {
        rotation: 0,
        x: 0,
        y: 0,
        duration: 0.5,
        ease: 'power4.inOut'
      })
      gsap.to(out_menu.current, {
        duration: 0.75,
        ease: 'power4.inOut',
        xPercent: 100
      })
    }
  }, [active])

  return (
    <>
      <div className="df fade-in aic bg-white jcsb m-20">
        <Link to="/" onClick={handleTransitionToHome}>
          <img src={logo_header} className="w-100px" alt="" />
        </Link>
        <div
          className="humberger"
          onClick={() => {
            setActive(!active)
          }}
          ref={burger}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
      <div className="out_menu dfc" ref={out_menu}>
      {projects.map((project, index) => (
          <Link to={project.projectLink} key={index} className="link" onClick={handleLinkClick}>
            {project.projectName}
          </Link>
        ))}
       
      </div>
    </>
  )
}

export default HamburgerMenu
