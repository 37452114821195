import { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";

import loadA from "./assets/img/load_a.png";
import loadB from "./assets/img/load_b.png";

export default function Loadingscreen() {
  const [progress, setProgress] = useState(0);
  const background = useRef();
  const background2 = useRef();
  const loadAREF = useRef();
  const loadBREF = useRef();

  useEffect(() => {
    // Simulate loading progress
    const interval = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress < 100) {
          return oldProgress + 100 / 150; // Increment to reach 100 in 1.5 seconds
        }
        clearInterval(interval);
        return 100;
      });
    }, 10); // Interval of 10 ms

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Trigger animations once progress reaches 100%
    if (progress === 100) {
      gsap.to(background.current, {
        duration: 2,
        transform: `translateX(100%)`,
        ease: "none",
        display: "none",
        delay: 0,
      });

      gsap.to(background2.current, {
        duration: 2,
        transform: `translateX(100%)`,
        display: "none",
        ease: "none",
        delay: 2, // 1 second delay
      });

      gsap.to(loadAREF.current, {
        duration: 1,
        opacity: 0,
        display: "none",
        ease: "none",
        delay: 0,
      });

      gsap.to(loadBREF.current, {
        duration: 2,
        opacity: 0,
        ease: "none",
        display: "none",
        delay: 1.5,
      });
    }
  }, [progress]);

  return (
    <>
      <div
        className="loading-screen__container__progress__bar"
        ref={background}
        style={{
          width: `100vw`,
          height: "100vh",
          background: "#210101",
          zIndex: 2,
          position: "absolute",
          top: 0,
        }}
      >
        <p
          style={{
            position: "absolute",
            top: "60%",
            marginLeft: "20px",
            zIndex: 99,
            fontSize: "20px",
            color: "white",
            fontFamily: "Kern Courant",
          }}
        >
          {/*Chargement ...*/}
        </p>
      </div>
      <div
        className="loading-screen__container__progress__bar"
        ref={background2}
        style={{
          width: `100vw`,
          height: "100vh",
          background: "#ffffff",
          position: "absolute",
          zIndex: 1,
          top: 0,
        }}
      ></div>
      <img
        src={loadB}
        ref={loadBREF}
        alt=""
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "25vw",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
      />
      <img
        src={loadA}
        ref={loadAREF}
        alt=""
        className="logo-anim_opacity"
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "25vw",
          transform: "translate(-50%, -50%)",
          zIndex: 3,
        }}
      />
    </>
  );
}
