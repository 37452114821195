/* REACT */
import { useLayoutEffect, useRef } from 'react'

/* STYLES */
import './assets/r.css'

/* GSAP */
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import HamburgerMenu from './HamburgerMenu'
import { useInView } from 'react-intersection-observer'

function Project({ project }) {
  const [footer, inView] = useInView({
    threshold: 0.9 // permet de définir le seuil de visibilité
  })

  const footerREF = useRef(null)

  useLayoutEffect(() => {
    gsap.from('.footer', {
      y: 250,
      duration: 5,
      scrollTrigger: {
        trigger: '.footer',
        start: 'top bottom',
        end: 'bottom center',
        scrub: true
      }
    })
  }, [inView])

  return (
    <>
      <div className="page">
        <HamburgerMenu />
        <hr className="m-20 mt-40" />
        <div className="df m-20 pr">
          <div className="df pos-left">
            <div className="df g-8">
              <div className="w-1 h-auto bg-black"></div>
              <div className="w-1 h-auto bg-black"></div>
              <div className="w-1 h-auto bg-black"></div>
              <div className="w-1 h-auto bg-black"></div>
            </div>
            <div className="h-auto col-10 bg-black w-48px">
              <p className="c-white tr--90 tt-u m-0"># {project.projectId}</p>
            </div>
          </div>
          <div className="ml-20 col-35 p-relative h-auto">
            <div className="p-sticky">
              <p className="tt-u m-0 mr-20 fw-800 fs-48">{project.projectName}</p>
              <p className="tt-u fs-24 mt-8">{project.projectPlace}</p>
            </div>
          </div>
          <div className="sep-row media-none"></div>
          <div className="dfc g-16 col-60 ml-20">
            {/* <img src={img_cover} className="w-450px" alt="" /> */}
            <div className="section dfc jcsb aifs">
              <p className="m-0 tt-u fw-700">Plan Avant / Après [BIENtôt]</p>
              <img src="https://www.archiprix.org/2021/project-images/2019/P19-2037//P19-2037_0117.jpg" style={{opacity:0.2}} alt="" className="w-50vw max-w-ft" />
            </div>
            <div className="mr-20 mdr-0">
              <div className="row-line mt-20 mb-8"></div>
              <div className="section dfc jcsb aifs">
                <p className="m-0 tt-u fw-700">{project.projectType}</p>
                <p className=" m-0 mt-12 lh-150 ">{project.projectSmallDescription}</p>
                <div className='project-details'>
                <div className="single-line">
                  <p>Surface : </p>
                  <p className="fw-700">{project.projectPlaceSize}</p>
                </div>
                <div className="single-line">
                  <p>Coût indicatif des travaux : </p>
                  <p className="fw-700">{project.projectCost}</p>
                </div>
                <div className="single-line">
                  <p>Durée : </p>
                  <p className="fw-700">{project.projectDuration}</p>
                </div>
                </div>
                <p className="m-0 tt-u fs-24 fw-700">{project.projectStatus}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row-line m-20"></div>

        <div className="df m-20 wrap g-20 w-100">
          <div className="dfc g-20 col-60 cmd85">
          {project.projectOtherPictures.map((image) => (
            <img src={image} className="w-100" alt="" />
          ))}
          </div>
          <div className="sep-row media-none"></div>

          <div className="h-auto col-35 cmd85">
            <img
              src={project.projectPolaroidPicture}
              alt=""
              className="w-webkit p-sticky w-100"
            />
          </div>
        </div>

        <div className="footer mb-40 trigger fw-600" ref={footerREF}>
          <div className="row-line m-20 mb-0"></div>
          <div className="m-20 mt-8">
            <p className="fs-14 m-0 tt-u">Bleu Fuchsia</p>
          </div>
          <div className="row-line m-20 mb-0"></div>
          <div className="m-20 mt-8">
            <p className="fs-14 m-0 tt-u">Alyssia ROSE, Architecte</p>
          </div>
          <div className="row-line m-20 mt-4 mb-0"></div>
          <div className="df aic ml-20 mt-12 ">
            <p className="fs-14 m-0 tt-u w-200">Adresse</p>
            <p className="fs-14 m-0">141 chemin des princes, 06650 Le rouret, FRANCE</p>
          </div>
          <div className="row-line m-20 mt-4 mb-0"></div>
          <div className="df aic ml-20 mt-12">
            <p className="fs-14 m-0 tt-u w-200">SIRET</p> 
            <p className="fs-14 m-0">92082737500014</p>
          </div>
          <div className="row-line m-20 mt-4 mb-0"></div>
          <div className="df aic ml-20 mt-12">
            <p className="fs-14 m-0 tt-u w-200">Email</p>
            <p className="fs-14 m-0"><a className="no-link" href='mailto:alyssiarose@bleufuchsia.com'>AlyssiaRose@bleufuchsia.com</a></p>
          </div>
          <div className="row-line m-20 mt-4 mb-0"></div>
          <div className="df aic ml-20 mt-12">
            <p className="fs-14 m-0 tt-u w-200">Téléphone</p>
            <p className="fs-14 m-0"><a className="no-link" href='tel:0645809464'>06 45 80 94 64</a></p>
          </div>
          <div className="row-line m-20 mt-4 mb-20"></div>
          <div className="df aic ml-20 mt-12">
            <p className="fs-14 m-0"><a className="no-link" href='/legal'>Mentions Légales</a></p>
          </div>
          <div className="df aic ml-20 mt-12">
            <p className="fs-8 m-0"><a className="no-link" href='https://nomad-agence-web.fr' target='_blank'>Website by NOMAD. Agence Web</a></p>
          </div>
        </div>

        <div className="sep"></div>
      </div>
    </>
  )
}

export default Project
