import { useState, useRef, useEffect } from 'react'
import { Canvas, useFrame, useLoader, useThree } from '@react-three/fiber'
import { CycleRaycast, BakeShadows, useCursor, softShadows, PresentationControls, SpotLight, PointLight } from '@react-three/drei'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { RepeatWrapping } from 'three'
import { MapControls } from '@react-three/drei'
import { projects, imageFiles } from './data.js'
import './assets/style.css'
import Underlay from './Underlay'
import LoadingScreen from './LoadingScreen';

import concreteImg from './assets/img/textures/concrete-2.jpg'

export default function App() {
  const [{ objects, cycle }, set] = useState({ objects: [], cycle: 0 })
  const [activeProject, setActiveProject] = useState('')

  // Define grid dimensions and spacing
  const rows = 6 // 4 rows
  const columns = 10 // 4 columns (for 16 images)
  const spacing = 1.4 // Adjust spacing as needed
  const imageSize = [1, 1.78] // Image ratio
  const inclination = 0.02 // Slight inclination angle

  // Load concrete texture
  const concreteTexture = useLoader(TextureLoader, concreteImg)

  useEffect(() => {
    if (concreteTexture) {
      concreteTexture.wrapS = RepeatWrapping
      concreteTexture.wrapT = RepeatWrapping
      concreteTexture.repeat.set(5, 5)
    }
  }, [concreteTexture])

  // Calculate position based on grid index with equal spacing
  const getPosition = (index) => {
    const row = Math.floor(index / columns)
    const column = index % columns
    const x = 2 + (column - (columns - 1) / 2) * spacing
    const y = (row - (rows - 1) / 2) * spacing * (imageSize[1] / imageSize[0]) + 3
    return [x, y, 0]
  }

  return (
    <>
      <Canvas className="fade-in"  shadows dpr={3} camera={{ fov: 60, near: 0.1, far: 1000, position: [1.5, 0, 6] }}>
        <MapControls enableRotate={false} maxDistance={8} minDistance={5} />
        <ambientLight intensity={0.5} />
        <directionalLight position={[10, 10, 0]} intensity={1.4} castShadow shadow-mapSize-width={2048} shadow-mapSize-height={2048} />

        <BakeShadows />
        <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -6, 0]}>
          <planeGeometry args={[30, 30]} />
          <meshStandardMaterial map={concreteTexture} /*color={'#210101'}*/ />
        </mesh>
        {imageFiles.map((img, index) => (
          <Picture
            key={index}
            position={getPosition(index, columns, rows, spacing)}
            rotation={[0, inclination, 0]}
            size={imageSize}
            project={img.project}
            imagePath={img.src}
            link={img.link}
            setActiveProject={setActiveProject}
          />
        ))}
      </Canvas>
      <Underlay project={activeProject} />
      <LoadingScreen />

    </>
  )
}

function Picture({ imagePath, project, setActiveProject, ...props }) {
  const texture = useLoader(TextureLoader, imagePath)
  const ref = useRef()
  const [hovered, setHovered] = useState(false)

  // Find the corresponding project
  const projectData = projects.find((p) => p.projectId === project)

  const handleHover = () => {
    setHovered(true)
    setActiveProject(projectData.projectName)
    document.body.style.cursor = 'zoom-in'
  }

  const handleUnhover = () => {
    setHovered(false)
    setActiveProject('')
    document.body.style.cursor = 'pointer'
  }

  useFrame(() => {
    ref.current.scale.setScalar(hovered ? 1.2 : 1)
  })

  const handleClick = () => {
    if (projectData && projectData.projectLink && projectData.projectName != '' && projectData.projectLink != 'TODO') {
      window.location.href = projectData.projectLink
    }
  }

  return (
    <mesh {...props} ref={ref} name={projectData.projectName} onClick={handleClick} onPointerOver={handleHover} onPointerOut={handleUnhover} castShadow>
      <planeGeometry args={[1, 1.78]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  )
}

softShadows()
