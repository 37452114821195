/*Import Images on homepage */
import img1 from './assets/img/home/1.jpg'
import img2 from './assets/img/home/2.jpg'
import img3 from './assets/img/home/3.jpg'
import img4 from './assets/img/home/4.jpg'
import img5 from './assets/img/home/5.jpg'
import img6 from './assets/img/home/6.jpg'
import img7 from './assets/img/home/7.jpg'
import img8 from './assets/img/home/8.jpg'
import img9 from './assets/img/home/9.jpg'
import img10 from './assets/img/home/10.jpg'
import img11 from './assets/img/home/11.jpg'
import img12 from './assets/img/home/12.jpg'
import img13 from './assets/img/home/13.jpg'
import img14 from './assets/img/home/14.jpg'
import img15 from './assets/img/home/15.jpg'
import img16 from './assets/img/home/16.jpg'
import img17 from './assets/img/home/17.jpg'
import img18 from './assets/img/home/18.jpg'
import img19 from './assets/img/home/19.jpg'
import img20 from './assets/img/home/20.jpg'
import img21 from './assets/img/home/21.jpg'
import img22 from './assets/img/home/22.jpg'
import img23 from './assets/img/home/23.jpg'
import img24 from './assets/img/home/24.jpg'
import img25 from './assets/img/home/25.jpg'
import img26 from './assets/img/home/26.jpg'
import img27 from './assets/img/home/27.jpg'
import img28 from './assets/img/home/28.jpg'
import img29 from './assets/img/home/29.jpg'
import img30 from './assets/img/home/30.jpg'
import img31 from './assets/img/home/31.jpg'
import img32 from './assets/img/home/32.jpg'
import img33 from './assets/img/home/33.jpg'
import img34 from './assets/img/home/34.jpg'
import img35 from './assets/img/home/35.jpg'
import img36 from './assets/img/home/36.jpg'
import img37 from './assets/img/home/37.jpg'
import img38 from './assets/img/home/38.jpg'
import img39 from './assets/img/home/39.jpg'
import img40 from './assets/img/home/40.jpg'

/*Images on homepage */
const imageFiles = [
  /* FIRST LINE */
  {
    src: img1,
    project: 5
  },
  {
    src: img2,
    project: 2
  },
  {
    src: img3,
    project: 3
  },
  {
    src: img4,
    project: 10
  },
  {
    src: img5,
    project: 9
  },
  {
    src: img6,
    project: 4
  },
  {
    src: img7,
    project: 4
  },
  {
    src: img8,
    project: 5
  },
  /* SECOND LINE */
  {
    src: img9,
    project: 10
  },
  {
    src: img10,
    project: 4
  },
  {
    src: img11,
    project: 7
  },
  {
    src: img12,
    project: 5
  },
  {
    src: img13,
    project: 2
  },
  {
    src: img14,
    project: 4
  },
  {
    src: img15,
    project: 2
  },
  {
    src: img16,
    project: 3
  },
  /* THIRD LINE */
  {
    src: img17,
    project: 3
  },
  {
    src: img18,
    project: 7
  },
  {
    src: img19,
    project: 7
  },
  {
    src: img20,
    project: 7
  },
  {
    src: img21,
    project: 10
  },
  {
    src: img22,
    project: 7
  },
  {
    src: img23,
    project: 7
  },
  {
    src: img24,
    project: 2
  },
  /* THOURTH LINE */
  {
    src: img25,
    project: 9
  },
  {
    src: img26,
    project: 5
  },
  {
    src: img27,
    project: 10
  },
  {
    src: img28,
    project: 9
  },
  {
    src: img29,
    project: 4
  },
  {
    src: img30,
    project: 3
  },
  {
    src: img31,
    project: 10
  },
  {
    src: img32,
    project: 5
  },
  /* THIFT LINE */
  {
    src: img33,
    project: 9
  },
  {
    src: img34,
    project: 9
  },
  {
    src: img35,
    project: 5
  },
  {
    src: img36,
    project: 3
  },
  {
    src: img37,
    project: 2
  },
  {
    src: img38,
    project: 2
  },
  {
    src: img39,
    project: 4
  },
  {
    src: img40,
    project: 9
  }
]


/*Import Images for projects */
/* # 1 */
import p1img1 from './assets/img/p1/1.jpg';
import p1img2 from './assets/img/p1/2.jpg';
import p1img3 from './assets/img/p1/3.jpg';
import p1img4 from './assets/img/p1/4.jpg';
import p1img5 from './assets/img/p1/5.jpg';
import p1img6 from './assets/img/p1/6.jpg';
import p1polaroide from './assets/img/p1/polaroide.jpg';

/* # 4 */
import p4img1 from './assets/img/p4/1.jpg';
import p4img2 from './assets/img/p4/2.jpg';
import p4img3 from './assets/img/p4/3.jpg';
import p4img4 from './assets/img/p4/4.jpg';
import p4img5 from './assets/img/p4/5.jpg';
import p4img6 from './assets/img/p4/6.jpg';
import p4img7 from './assets/img/p4/7.jpg';
import p4img8 from './assets/img/p4/8.jpg';
import p4img9 from './assets/img/p4/9.jpg';
import p4img10 from './assets/img/p4/10.jpg';
import p4polaroide from './assets/img/p4/polaroide.jpg';

/* # 5 */
import p5img1 from './assets/img/p5/1.jpg';
import p5img2 from './assets/img/p5/2.jpg';
import p5img3 from './assets/img/p5/3.jpg';
import p5img4 from './assets/img/p5/4.jpg';
import p5img5 from './assets/img/p5/5.jpg';
import p5img6 from './assets/img/p5/6.jpg';
import p5img7 from './assets/img/p5/7.jpg';
import p5polaroide from './assets/img/p5/polaroide.jpg';

/* # 7 */
import p7img1 from './assets/img/p7/1.jpg';
import p7img2 from './assets/img/p7/2.jpg';
import p7img3 from './assets/img/p7/3.jpg';
import p7img4 from './assets/img/p7/4.jpg';
import p7img5 from './assets/img/p7/5.jpg';
import p7img6 from './assets/img/p7/6.jpg';
import p7img7 from './assets/img/p7/7.jpg';
import p7img8 from './assets/img/p7/8.jpg';
import p7polaroide from './assets/img/p7/polaroide.jpg';

/* # 8 */
import p8img1 from './assets/img/p8/1.jpg';
import p8img2 from './assets/img/p8/2.jpg';
import p8img3 from './assets/img/p8/3.jpg';
import p8img4 from './assets/img/p8/4.jpg';
import p8img5 from './assets/img/p8/5.jpg';
import p8img6 from './assets/img/p8/6.jpg';
import p8img7 from './assets/img/p8/7.jpg';
import p8img8 from './assets/img/p8/8.jpg';
import p8img9 from './assets/img/p8/9.jpg';
import p8img10 from './assets/img/p8/10.jpg';
import p8img11 from './assets/img/p8/11.jpg';
import p8img12 from './assets/img/p8/12.jpg';
import p8polaroide from './assets/img/p8/polaroide.jpg';


const projects = [
  {
    projectId: 1,
    projectLink: 'TODO',
    projectName: '',
    projectPlace: 'Location 2',
    projectType: 'Type 2',
    projectSmallDescription: 'Description 2',
    projectLandSize: 'Land Size 2',
    projectPlaceSize: 'Place Size 2',
    projectCost: 'Cost 2',
    projectDuration: 'Duration 2',
    projectStatus: 'Status 2',
    projectOtherPictures: [p1img1, p1img2, p1img3, p1img4],
    projectPolaroidPicture: img5
  },
    {
      projectId: 2,
      projectLink: '/2-projet-sepia-renovation-appartement',
      projectName: 'Projet Sepia - Rénovation d\'un appartement',
      projectPlace: 'Grasse',
      projectType: 'Rénovation d\'un appartement',
      projectSmallDescription: 'Mission de décoration et design de mobilier pour la cuisine le salon et les espaces de circulation de l’appartement. Suivi de chantier et livraison.',
      projectLandSize: '-',
      projectPlaceSize: '100m²',
      projectCost: '25 000€',
      projectDuration: '4 MOIS',
      projectStatus: 'PROJET LIVRé',
      projectOtherPictures: [p1img1, p1img2, p1img3, p1img4, p1img5, p1img6],
        projectPolaroidPicture: p1polaroide
    },
    
    {
      projectId: 3,
      projectLink: 'TODO',
      projectName: '',
      projectPlace: 'Location 2',
      projectType: 'Type 2',
      projectSmallDescription: 'Description 2',
      projectLandSize: 'Land Size 2',
      projectPlaceSize: 'Place Size 2',
      projectCost: 'Cost 2',
      projectDuration: 'Duration 2',
      projectStatus: 'Status 2',
      projectOtherPictures: [img3, img4],
      projectPolaroidPicture: img5
    },
    {
      projectId: 4,
      projectLink: '/4-projet-outremer-amenagement-de-bureaux',
      projectName: 'Projet Outremer - Aménagement de bureaux',
      projectPlace: 'Sophia-Antipolis',
      projectType: 'Aménagement de bureaux',
      projectSmallDescription: 'Mission d’aménagement et rénovation d’un plateau de 400m² en bureaux. Conception des espaces, analyse des pratique de travail et suivi de chantier jusqu’à la livraison.',
      projectLandSize: '-',
      projectPlaceSize: '450m²',
      projectCost: '340 000€',
      projectDuration: '4 MOIS',
      projectStatus: 'Projet Livré',
      projectOtherPictures: [p4img1, p4img2, p4img3, p4img4, p4img5, p4img6, p4img7, p4img8, p4img9, p4img10],
      projectPolaroidPicture: p4polaroide 
    },
    {
      projectId: 5,
      projectLink: '/5-projet-carmin-ravalement-de-facade',
      projectName: 'Projet Carmin - Ravallement de facade',
      projectPlace: 'Grasse',
      projectType: 'Patrimoine ravalement de facade',
      projectSmallDescription: 'Mission de maîtrise d’oeuvre pour un ravalement de facade, suivi de chantier et livraison. ',
      projectLandSize: '450m²',
      projectPlaceSize: '-',
      projectCost: '340 000€', 
      projectDuration: '4 MOIS',
      projectStatus: 'Projet en cours',
      projectOtherPictures: [p5img1, p5img2, p5img3, p5img4, p5img5, p5img6, p5img7],
      projectPolaroidPicture: p5polaroide
    },
    {
      projectId: 6,
      projectLink: 'TODO',
      projectName: '',
      projectPlace: 'Location 2',
      projectType: 'Type 2',
      projectSmallDescription: 'Description 2',
      projectLandSize: 'Land Size 2',
      projectPlaceSize: 'Place Size 2',
      projectCost: 'Cost 2',
      projectDuration: 'Duration 2',
      projectStatus: 'Status 2',
      projectOtherPictures: [img3, img4],
      projectPolaroidPicture: img5
    },
    {
      projectId: 7,
      projectLink: '/7-projet-emeraude-renovation-piece',
      projectName: 'Projet Emeraude - Rénovation d\'une pièce',
      projectPlace: 'Le Bar sur Loup',
      projectType: 'Rénovation d\'une pièce',
      projectSmallDescription: 'Mission de décoration et design de mobilier pour la bibliothèque d’une maison bourgeoise. Suivi de chantier et livraison.',
      projectLandSize: '-',
      projectPlaceSize: '16m²',
      projectCost: '11 000€',
      projectDuration: '2 Mois',
      projectStatus: 'Projet livré',
      projectOtherPictures: [p7img1, p7img2, p7img3, p7img4, p7img5, p7img6, p7img7, p7img8],
      projectPolaroidPicture: p7polaroide
    },
    {
      projectId: 8,
      projectLink: '/8-projet-aureoline-renovation-piece',
      projectName: 'Projet Auréoline - Rénovation d\'une pièce',
      projectPlace: 'Le Bar sur Loup',
      projectType: 'Rénovation d\'une pièce',
      projectSmallDescription: 'Mission de décoration et design de mobilier pour le salon d’une maison bourgeoise. Suivi de chantier et livraison.',
      projectLandSize: '-',
      projectPlaceSize: '28m²',
      projectCost: '17 000€',
      projectDuration: '2 Mois',
      projectStatus: 'Projet livré',
      projectOtherPictures: [p8img1, p8img2, p8img3, p8img4, p8img5, p8img6, p8img7, p8img8, p8img9, p8img10, p8img11, p8img12],
      projectPolaroidPicture: p8polaroide
    },
    {
      projectId: 9,
      projectLink: 'TODO',
      projectName: '',
      projectPlace: 'Location 2',
      projectType: 'Type 2',
      projectSmallDescription: 'Description 2',
      projectLandSize: 'Land Size 2',
      projectPlaceSize: 'Place Size 2',
      projectCost: 'Cost 2',
      projectDuration: 'Duration 2',
      projectStatus: 'Status 2',
      projectOtherPictures: [p8img1, p8img2, p8img3, p8img4, p8img5, p8img6, p8img7, p8img8, p8img9, p8img10, p8img11, p8img12],
      projectPolaroidPicture: p8polaroide
    },
    {
      projectId: 10,
      projectLink: 'TODO',
      projectName: "Projet Valériane - Rénovation Restaurant",
      projectPlace: 'Location 2',
      projectType: 'Type 2',
      projectSmallDescription: 'Description 2',
      projectLandSize: 'Land Size 2',
      projectPlaceSize: 'Place Size 2',
      projectCost: 'Cost 2',
      projectDuration: 'Duration 2',
      projectStatus: 'Status 2',
      projectOtherPictures: [img3, img4],
      projectPolaroidPicture: img5
    }
  ];

export { imageFiles, projects }
